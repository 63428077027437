// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-coronavirus-covid-19-js": () => import("./../../../src/pages/coronavirus-covid-19.js" /* webpackChunkName: "component---src-pages-coronavirus-covid-19-js" */),
  "component---src-pages-demo-5-minute-js": () => import("./../../../src/pages/demo-5minute.js" /* webpackChunkName: "component---src-pages-demo-5-minute-js" */),
  "component---src-pages-example-table-js": () => import("./../../../src/pages/example-table.js" /* webpackChunkName: "component---src-pages-example-table-js" */),
  "component---src-pages-faqs-hellohealth-js": () => import("./../../../src/pages/faqs-hellohealth.js" /* webpackChunkName: "component---src-pages-faqs-hellohealth-js" */),
  "component---src-pages-faqs-telehealth-js": () => import("./../../../src/pages/faqs-telehealth.js" /* webpackChunkName: "component---src-pages-faqs-telehealth-js" */),
  "component---src-pages-get-started-js": () => import("./../../../src/pages/get-started.js" /* webpackChunkName: "component---src-pages-get-started-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-plans-js": () => import("./../../../src/pages/plans.js" /* webpackChunkName: "component---src-pages-plans-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-training-center-hellohealth-js": () => import("./../../../src/pages/training-center-hellohealth.js" /* webpackChunkName: "component---src-pages-training-center-hellohealth-js" */),
  "component---src-pages-training-center-telehealth-js": () => import("./../../../src/pages/training-center-telehealth.js" /* webpackChunkName: "component---src-pages-training-center-telehealth-js" */),
  "component---src-templates-benefit-post-js": () => import("./../../../src/templates/BenefitPost.js" /* webpackChunkName: "component---src-templates-benefit-post-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/Post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-post-list-js": () => import("./../../../src/templates/PostList.js" /* webpackChunkName: "component---src-templates-post-list-js" */),
  "component---src-templates-product-post-js": () => import("./../../../src/templates/ProductPost.js" /* webpackChunkName: "component---src-templates-product-post-js" */),
  "component---src-templates-release-list-js": () => import("./../../../src/templates/ReleaseList.js" /* webpackChunkName: "component---src-templates-release-list-js" */),
  "component---src-templates-release-post-js": () => import("./../../../src/templates/ReleasePost.js" /* webpackChunkName: "component---src-templates-release-post-js" */),
  "component---src-templates-resource-list-js": () => import("./../../../src/templates/ResourceList.js" /* webpackChunkName: "component---src-templates-resource-list-js" */),
  "component---src-templates-resource-post-js": () => import("./../../../src/templates/ResourcePost.js" /* webpackChunkName: "component---src-templates-resource-post-js" */),
  "component---src-templates-service-post-js": () => import("./../../../src/templates/ServicePost.js" /* webpackChunkName: "component---src-templates-service-post-js" */),
  "component---src-templates-specialty-post-js": () => import("./../../../src/templates/SpecialtyPost.js" /* webpackChunkName: "component---src-templates-specialty-post-js" */),
  "component---src-templates-tag-post-js": () => import("./../../../src/templates/TagPost.js" /* webpackChunkName: "component---src-templates-tag-post-js" */)
}

